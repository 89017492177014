
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding-bottom: 64px; /* Altura do rodapé para evitar sobreposição */
  padding-top: 64px;
}
