/* Estilo específico de fonte para o login */
.login-container-pagamentos {
  font-family: 'Segoe UI'; 
}

.text-portal {
  font-family: 'Segoe UI'; 
}

.description-portal {
  font-family: 'Segoe UI'; 
}

/* Estilos para diferentes tamanhos de tela */
@media (min-width: 1230px) {
  .login-container-pagamentos { 
      margin: 0;
      padding: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: flex-end;
      padding-right: 15%;
      align-items: center;
       /* Imagem de fundo personalizada */
      background:  url('../../public/dist/img/an_elementos-17.png');
      background-size: contain; /* Ajusta o tamanho da imagem */
      background-position: center;
      background-repeat: no-repeat;
      background-color: #084e5a; 
  }

  .form-container-1-pagamentos {
      color: white;
      width: 525px;
      background-color: rgba(255, 255, 255, 0);
      display: flex;
      margin: 5px;
      flex-direction: column;
      gap: 20px;
      padding: 30px 20px;
      border-radius: 10px;
      margin-right: 10vw;
      padding-left: 60px;
  }

  .form-container-2-pagamentos {
      color: white;
      width: 330px;
      background-color: rgba(255, 255, 255, 0.15);
      display: flex;
      margin: 5px;
      flex-direction: column;
      gap: 20px;
      padding: 30px 20px;
      border-radius: 10px;
      margin-left: auto;
  }
}

@media (min-width: 1025px) and (max-width: 1229px) {
  .login-container-pagamentos { 
      margin: 0;
      padding: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: flex-end;
      padding-right: 15%;
      align-items: center;
      /* Imagem de fundo personalizada */
      background-image: url('../../public/dist/img/an_elementos-17.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #084e5a;
  }

  .form-container-1-pagamentos {
      color: white;
      width: 525px;
      max-width: 60%;
      background-color: rgba(255, 255, 255, 0);
      display: flex;
      margin: 5px;
      flex-direction: column;
      gap: 20px;
      padding: 30px 20px;
      border-radius: 10px;
      margin-right: 2vw;
      padding-left: 60px;
  }

  .form-container-2-pagamentos {
      color: white;
      width: 330px;
      max-width: 35%;
      background-color: rgba(255, 255, 255, 0.15);
      display: flex;
      margin: 5px;
      flex-direction: column;
      gap: 20px;
      padding: 30px 20px;
      border-radius: 10px;
      margin-left: auto;
  }
}

@media (max-width: 1024px) {
  .login-container-pagamentos {
      margin: 0;
      padding: 0; 
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      /* Imagem de fundo personalizada */
      background-image: url('../../public/dist/img/an_elementos-17.png');
      background-size: cover; /* Preenche todo o background */
      background-position: center;
      background-repeat: no-repeat;
      background-color: #084e5a;
  }

  .form-container-1-pagamentos {
      display: none;
  }

  .form-container-2-pagamentos {
      color: white;
      width: 330px;
      background-color: rgba(255, 255, 255, 0.15);
      display: flex;
      margin: 5px;
      flex-direction: column;
      gap: 20px;
      padding: 30px 20px;
      border-radius: 10px;
  }
}  

/* Estilos dos campos de entrada */
.input-group-pagamentos {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.input-group-pagamentos input {
  width: 100%;
}

.input-group-pagamentos-senha {
  position: relative;
  display: flex;
  justify-content: flex-end;
}


.input-group-pagamentos-senha input {
  width: 100%;
}

.campo-pagamentos {
  border: solid 1.5px #9e9e9e;
  border-radius: 1rem;
  background: none;
  padding: 1rem;
  font-size: 1rem;
  color: #f5f5f5;
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.campo-pagamentos ~ label {
  position: absolute;
  left: 16px;
  color: #ffffff;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.campo-pagamentos:focus {
  background: none;
  outline: none;
  border: 1.5px solid #14a2b8; /* Cor personalizada */
}

.campo-pagamentos:focus ~ label {
  transform: translateY(-50%) scale(0.9);
  background-color: #006d8300;
  padding: 0 0.2em;
  color: #ffffff;
}

/* Estilos do botão */
.btn-entrar-pagamentos {
  padding: 10px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  background-color: #14a2b8; /* Cor personalizada */
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.btn-entrar-pagamentos:hover {
  background-color: #13c0db;
}

.btn-entrar-pagamentos:active {
  background-color: #158495;
}

.btn-entrar-pagamentos:disabled {
  color: #555455;
  background: #302e30;
  cursor: not-allowed;
}

/* Estilos adicionais para mensagens de erro e animações */
.invalid-pagamentos ~ label{
  transform: translateY(-50%) scale(0.9);
  background-color: #c0392b;
  padding: 0 0.2em;
  color: white;
}

.invalid-border-pagamentos{
  border-color: #e74c3c;
  animation: shake 0.5s ease-in-out;
  animation-iteration-count: 2;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.foco-pagamentos ~ label{
  transform: translateY(-50%) scale(0.9);
  background-color: #34495e00;
  padding: 0 0.2em;
  color: rgb(145, 145, 145);
}

/* Estilos do relógio */
.clock-pagamentos {
  position: relative;
  margin: auto;
  border-radius: 50%;
  border: 2px solid white;
  width: 40px;
  height: 40px;
}

.clock-pagamentos::after {
  position: absolute;
  width: 0px;
  height: 12px;
  display: block;
  border-left: 2px solid #ecf0f1;
  content: "";
  left: 19px;
  top: 8px;
  transform-origin: bottom;
  animation-duration: 60s;
  animation-name: animation-minutes;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes animation-minutes {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.time-pagamentos {
  text-align: center;
  color: white;
  margin-top: 10px;
  font-size: 1.2rem;
}

/* Estilos do botão de mostrar/ocultar senha */
.show-hide-login * {
  color: #9e9e9e;
}

.show-hide-login {
  position: absolute;
  left: 80%;
  bottom: 12px;
}


.icon-heading {
  display: flex;
  align-items: center;
  margin-top: -30px;
  /* Adicione mais estilos conforme necessário */
}

.icon-heading img {
  width: 300px; /* Ajuste para o tamanho desejado */
  height: auto; /* Mantém a proporção da imagem */
  max-width: 100%; /* Evita que a imagem ultrapasse os limites do container */
  object-fit: contain; /* Garante que a imagem se ajuste sem distorção */
  border-radius: 5px; /* Opcional, para bordas arredondadas */
}

.text-portal {
  margin-top: -20px;
  color: #cccccd; /* Cor padrão do texto */
  font-size: 2.5rem;
  font-weight: 200;
}

.description-portal {
  margin-top: -30px;
  color: #aaaab3; /* Cor padrão do texto */
  font-size: 1.2rem;
  font-weight: 200;
}

.wrapper-social {
    display: flex;
    justify-content: left;
    align-content: center;
}

.wrapper-social .icon {
    margin-top: -35px;
    margin-left: -15px;
    position: relative;
    border-radius: 50%;
    padding: 15px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: left;
    align-items: left;
    flex-direction: column;
    cursor: pointer;
}

.href-social {
    color: white;
}

.href-social:hover {
    color: rgb(200, 193, 193);
}
